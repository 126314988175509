import React from "react"
import Layout from "../components/layout"
import {Helmet} from 'react-helmet';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const IletisimPage = ({ location }) => {
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (
        <Layout>
            <GatsbySeo
                title="İletişim"
                description="Varlıktan Veriler iletişim bilgileri. Bize ulaşmak için neler yapabileceğini öğren"
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: "Varlıktan Veriler İletişim",
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>İletişim</h1>
                    <span>VarlıktanVeriler.com iletişim bilgileri</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container clearfix">
                        <div className="row gutter-40 col-mb-80">
                            <div className="postcontent col-lg-9">

                                <h3>İletişim Formu</h3>

                                <div className="form-widget">

                                    <div className="form-result"></div>

                                    <Helmet>
                                        <script src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`} async defer></script>
                                    </Helmet>
                                    <form className="row mb-0" id="template-contactform" name="template-contactform" action="https://getform.io/f/28a0bfe0-bf7c-49ae-bc25-4385c36f1236" method="post" >

                                        <div className="form-process">
                                            <div className="css3-spinner">
                                                <div className="css3-spinner-scaler"></div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group">
                                            <label htmlFor="template-contactform-name">İsim <small>*</small></label>
                                            <input type="text" id="template-contactform-name" name="template-contactform-name" className="sm-form-control required"/>
                                        </div>

                                        <div className="col-md-4 form-group">
                                            <label htmlFor="template-contactform-email">Email <small>*</small></label>
                                            <input type="email" id="template-contactform-email" name="template-contactform-email" className="required email sm-form-control"/>
                                        </div>

                                        <div className="col-md-4 form-group">
                                            <label htmlFor="template-contactform-phone">Telefon</label>
                                            <input type="text" id="template-contactform-phone" name="template-contactform-phone"  className="sm-form-control"/>
                                        </div>

                                        <div className="w-100"></div>

                                        <div className="col-md-12 form-group">
                                            <label htmlFor="template-contactform-subject">Konu <small>*</small></label>
                                            <input type="text" id="template-contactform-subject" name="subject" className="required sm-form-control"/>
                                        </div>

                                        <div className="col-12 form-group">
                                            <label htmlFor="template-contactform-message">Mesaj <small>*</small></label>
                                            <textarea className="required sm-form-control" id="template-contactform-message" name="template-contactform-message" rows="6" cols="30"></textarea>
                                        </div>

                                        <div className="col-12 form-group d-none">
                                            <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" className="sm-form-control"/>
                                        </div>

                                        <div className="col-12 form-group">
                                            {/*<script src="https://www.google.com/recaptcha/api.js" async defer></script>*/}
                                            {/*<div className="g-recaptcha" data-sitekey="6LfijgUTAAAAACPt-XfRbQszAKAJY0yZDjjhMUQT"></div>*/}

                                        </div>

                                        <div className="col-12 form-group">
                                            <button aria-label="Formu Gnnder"
                                                    className="g-recaptcha button button-3d m-0"
                                                    data-sitekey="6LfP1z8aAAAAAOjL2tGbsNUgNhXbmo8-3ZjCrAfD"
                                                    data-callback='onSubmit'
                                                    data-action='submit'>Gönder
                                            </button>
                                        </div>

                                        <input type="hidden" name="prefix" value="template-contactform-"/>
                                        <input type="hidden" id="captchaResponse" name="g-recaptcha-response"/>

                                    </form>

                                </div>

                            </div>

                            <div className="sidebar col-lg-3">

                                <abbr title="Email Address"><strong>Email:</strong></abbr> iletisim@varliktanveriler.com
                                <div className="widget border-0 pt-0">

                                    <a href="https://www.facebook.com/varliktanveriler"
                                       aria-label="Facebook"
                                       className="social-icon si-dark si-mini si-facebook" target="_blank" rel="noreferrer">
                                        <i className="icon-facebook"/>
                                        <i className="icon-facebook"/>
                                    </a>
                                    <a href="https://twitter.com/varliktanveri"
                                       aria-label="Twitter"
                                       className="social-icon si-dark si-mini si-twitter" target="_blank" rel="noreferrer">
                                        <i className="icon-twitter"/>
                                        <i className="icon-twitter"/>
                                    </a>
                                    <a href="https://www.instagram.com/varliktanveriler"
                                       aria-label="Instagram"
                                       className="social-icon si-dark si-mini si-instagram" target="_blank" rel="noreferrer">
                                        <i className="icon-instagram"/>
                                        <i className="icon-instagram"/>
                                    </a>
                                    <a href="https://www.youtube.com/user/varliktanveriler"
                                       aria-label="Youtube"
                                       className="social-icon si-dark si-mini si-youtube" target="_blank" rel="noreferrer">
                                        <i className="icon-youtube"/>
                                        <i className="icon-youtube"/>
                                    </a>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default IletisimPage
